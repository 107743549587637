import React, { useEffect } from "react"
import { useDispatch } from 'react-redux';
import { graphql } from 'gatsby';
import styled, { createGlobalStyle } from 'styled-components';
import { LargeHeader } from "../components/TextComponents/TextComponents";
import HtmlParser from '../components/HtmlParser/HtmlParser';
import Hero from '../components/Hero/Hero';
import Seo from "../components/Seo/Seo";
import HeroImage from './assets/Book Now_main image@2x.jpg';
import HeroImageBlur from './assets/Book Now_main image@2x_blur.jpg';
import HeroMobileImage from './assets/mobile/Book Now_main image@2x.jpg';
import HeroMobileImageBlur from './assets/mobile/Book Now_main image_blur@2x.jpg';
import { setNavColor, setNavDefaultColor, setCurrentPage } from "../actions";
import { fontstack } from '../utils/fontstack';
import { type } from '../utils/type';
import '../components/Layout/fonts/fonts.css';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: #f2f2f2;
  }
`

const Wrapper = styled.div` 
  position: relative;
`

const Content = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 216px;
  color: white;
`

// markup
const MaintenacePage = () => {
  return (
    <div>
      <GlobalStyle />
      <Seo title="Maintenace"/>
      <Wrapper>
      <Hero desktop={{src: HeroImage, placeholder: HeroImageBlur}} 
      mobile={{src: HeroMobileImage, placeholder: HeroMobileImageBlur}}/>
        <Content>
          <LargeHeader style={{margin: '0 0 25px'}}>Coming Soon</LargeHeader>
        </Content>
      </Wrapper>  
    </div>
  )
}

export default MaintenacePage;